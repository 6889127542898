<template>
  <div class="bb0-history-container">
    <w-table
      :tableProperties="tableDataValues"
      :tableLoading="tableLoding"
      :showHeading="true"
      :headingText="'Optimization History'"
      :customRowColor="true"
      @retryModel="restartModel($event)"
      :noDataText="'No Model found'"
      @viewModelResult="viewModelResult($event)"
    >
    </w-table>
    <w-table
      :tableProperties="tableDataValues1"
      :tableLoading="tableLoding1"
      :showHeading="true"
      :headingText="'Forecasting History'"
      :customRowColor="true"
      @retryModel="restartModel1($event)"
      :noDataText="'No Model found'"
      @viewModelResult="viewModelResult1($event)"
    >
    </w-table>
  </div>
</template>

<script>
import DataTable from "@/widgets/DataTable.vue";

import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
const aimoUnileverService = new AimoUnileverServices();

export default {
  name: "OptimizationHistory",
  components: {
    "w-table": DataTable,
  },
  data() {
    return {
      tableLoding: false,
      tableLoding1: false,
      tableDataValues: {
        fields: [
          {
            key: "Started",
            label: "Started",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Brand",
            label: "Brand",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Duration",
            label: "Duration",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "KPI",
            label: "KPI",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },

          {
            key: "Total Budget",
            label: "Total Budget",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Progress",
            label: "Progress",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Status",
            label: "Status",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "actionRowUL",
            label: "Action",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      tableDataValues1: {
        fields: [
          {
            key: "Started",
            label: "Started",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Brand",
            label: "Brand",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Duration",
            label: "Duration",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "KPI",
            label: "KPI",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },

          {
            key: "Total Budget",
            label: "Total Budget",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Progress",
            label: "Progress",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Status",
            label: "Status",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "actionRowUL",
            label: "Action",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    restartModel() {},
    viewModelResult(e) {
      const budget = e["Total Budget"] / 1000000;
      const months = e.Duration;
      let clientId = sessionStorage.getItem("subId");

      aimoUnileverService.viewResultFromHistory(e._id, clientId).then((res) => {
        localStorage.setItem("optimizeData", JSON.stringify(res));
        sessionStorage.setItem("currentTab", "bbo");
        sessionStorage.setItem("fromHistoryTable", true);
        sessionStorage.setItem("fronScenarioPlanning", false);

        sessionStorage.setItem(
          "obbData",
          JSON.stringify({
            month: months,
            budget: [budget],
          })
        );
        this.$router.push("/solutions/acko/scenario-planning/planning-results");
        // localStorage.setItem("optimizeData", res)
      });
    },
    viewModelResult1(e) {
      const budget = e["Total Budget"] / 1000000;
      const months = e.Duration;
      let clientId = "forecasting_cqzvoknxsjtbihvpgcsy";

      aimoUnileverService
        .viewResultFromHistory("af8a2128-4860-4520-bff3-d29d0cccecb6", clientId)
        .then((res) => {
          localStorage.setItem("optimizeData", JSON.stringify(res));
          sessionStorage.setItem("currentTab", "forecasting");
          sessionStorage.setItem("fromHistoryTable", true);
          sessionStorage.setItem("fronScenarioPlanning", false);

          sessionStorage.setItem(
            "obbData",
            JSON.stringify({
              month: months,
              budget: [budget],
            })
          );
          this.$router.push("/solutions/acko/scenario-planning/planning-results");
          // localStorage.setItem("optimizeData", res)
        });
    },
    getOptimizationHistoryTable() {
      this.tableLoding = true;
      var clientId = sessionStorage.getItem("subId");
      aimoUnileverService.getSummaryTableData(clientId).then((res) => {
        // sessionStorage.setItem("currentTab", "bbo");
        this.tableLoding = false;
        this.tableDataValues.items = [];
        this.tableDataValues.items = res;
      });
    },
    getOptimizationHistoryTable1() {
      this.tableLoding = true;
      var clientId = "forecasting_cqzvoknxsjtbihvpgcsy";
      aimoUnileverService.getSummaryTableData(clientId).then((res) => {
        // sessionStorage.setItem("currentTab", "bbo");
        this.tableLoding1 = false;
        this.tableDataValues1.items = [];
        this.tableDataValues1.items = res;
      });
    },
  },
  created() {
    this.getOptimizationHistoryTable();
    this.getOptimizationHistoryTable1();
  },
};
</script>

<style scoped>
.bb0-history-container {
  margin-top: 10%;
  margin-left: 10%;
  height: 100vh;
  width: 80%;
}
</style>
<!-- forecasting_cqzvoknxsjtbihvpgcsy -->
